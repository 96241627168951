import React from 'react';
import {useLocation} from 'react-router-dom';
import {GetApiData} from './../Utils/auth.js';
import {ForexponentialError} from './../Utils/error.js';
import Loading from '../Utils/loading.js';

export default function ArticlePage() {
	const [article,setArticle]=React.useState({loaded:false});
	const location=useLocation();

	React.useEffect(() => {
		if(article.loaded!==false) return;
		let articleID=location.pathname.split("/")[2];
		const cleanup=GetApiData("article.aspx",null,{ArticleID:articleID},null,data => setArticle({loaded:true,...data}));
		return cleanup;
	},[article,location.pathname])

	if(!article.loaded) return <Loading message="article" />
	if(article.error) return <ForexponentialError message="Apologies, we seem to be having difficulty accessing this article." error={article.error} />
	if(!article.Article) return <ForexponentialError message="Apologies, we couldn't find this article." />

	return (
		<article>
			<h1 style={{textAlign:"center",margin:"1rem"}}>{article.Article.Title}</h1>
			<small>{article.Article.DateTime}</small>
			<div>{article.Article.Content}</div>
		</article>
	)
};
