import React,{useContext} from 'react';
import {FxpContext,GetApiData} from './../Utils/auth.js';
import {ValidateInput,ForexponentialInput,ForexponentialInputPassword,ForexponentialInputError} from './../Utils/form-fields.js';

export default function LoginPage() {
	const [login,setLogin]=React.useState({});
	const FxpCtx=useContext(FxpContext);
	const refUsername=React.useRef(null);

	const handleLogin = e => {
		e.preventDefault();
		setLogin({active:true});
		let inputValid=true;
		e.target.querySelectorAll("input").forEach(input => {inputValid=(ValidateInput(input)===false ? false : true) && inputValid});
		if(!inputValid) return setLogin({});
		GetApiData("login.aspx",null,null,e.target,data => {
			if(data.error) return setLogin(data);
			setLogin({});
			FxpCtx.login(data);
		});
	}

	const resetPassword = e => {
		setLogin({});
		GetApiData("login-reset.aspx",null,{Username:refUsername.current.value},null,data => {
			setLogin(data);
			if(!data.error) FxpCtx.dialogDispatch({type:"SHOW_CONTENT",payload:{header:"Success",body:"An e-mail has been sent to you.<br />Please click the link in the e-mail to access your account."}});
		});
	}

	return (<>
		<h2 style={{textAlign:"center",margin:"1rem"}}>Please enter your login details</h2>
		<form className="forexponentialForm narrow" onSubmit={handleLogin}>
			<ForexponentialInput Ref={refUsername} ID="LoginUsername" Name="Username" Label="E-mail address" Type="email" />
			<ForexponentialInputPassword ID="LoginPassword" Name="Password" Label="Password" />
			<div className="form-buttons">
				<button type="submit" style={{minWidth:"10rem"}}>{login.active ? <i className="fas fa-circle-notch fa-spin" /> : "Log in"}</button>
			</div>
			<ForexponentialInputError Message={login.error ? [login.error] : []} MarginTop={true} />
			<div className="form-buttons">
				<button type="button" className="btn-link" onClick={resetPassword}>Forgotten details?</button>
			</div>
		</form>
	</>)
}
