import React from 'react';

export default function Loading(props) {
	const [display,setDisplay]=React.useState(false);
	React.useEffect(() => {
		const timer=setTimeout(() => setDisplay(true),1000);
		return () => clearTimeout(timer);
	},[]);

	if(!display) return <></>
	return (
		<div style={{textAlign:"center",marginTop:"2rem"}}>
			<i className="fas fa-circle-notch fa-spin fa-4x" />
			<br />
			<h3>Loading {props.message}</h3>
		</div>
	)
}
