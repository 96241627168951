import React from 'react';
import {useSearchParams,Link} from "react-router-dom";
import {GetApiData} from './../Utils/auth.js';
import {ForexponentialError} from './../Utils/error.js';
import Loading from '../Utils/loading.js';

export default function ConfirmationCode() {
	const [searchParams]=useSearchParams();
	const [confirmation,setConfirmation]=React.useState({loaded:false});
	const Code=searchParams.get("Code"),Type=searchParams.get("Type");
	React.useEffect(() => {
		if(confirmation.loaded) return;
		return GetApiData("confirmation-resend.aspx",null,{Type,Code},null,data => setConfirmation({loaded:true,...data}));
	},[Code,Type,confirmation])

	if(!confirmation.loaded) return <Loading message="confirmation" />
	if(confirmation.error) return <ForexponentialError message="Apologies, we seem to be having difficulty accessing your account data." error={confirmation.error} />
	return (
		<div style={{textAlign:"center",margin:"2rem 0"}}>
			<h2>Thank you</h2>
			<p>Your <b>{Type}</b> has been confirmed.</p>
			<p>Please <Link to="/login">log in</Link> to continue to your account.</p>
		</div>
	)
};
