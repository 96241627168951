import React from 'react';

export default function FAQPage() {
	const [selectedFAQ,setSelectedFAQ]=React.useState(null);
	const [listFAQs,setListFAQs]=React.useState([]);


	const selectFaq=clickedFAQ => {
		setSelectedFAQ(clickedFAQ===selectedFAQ ? null : clickedFAQ);
	}

	React.useEffect(() => {
		if(listFAQs.length>0) return;
		const newListFAQs=[];
		newListFAQs.push({id:0,q:"What is Forexponential?",a:"Forexponential is an investment system, based on FOREX trading. We invest your money into the foreign exchange markets (FOREX) using our sophisticated algorithms and pass the profits on to you.<br /><br />The FOREX market has the potential to make a lot more interest than traditional banks or building societies. In fact, you will usually earn more interest in one month than a bank will give you in a whole year."});
		newListFAQs.push({id:1,q:"What is FOREX?",a:"FOREX is short for foreign exchange. The exchange rates between different pairs of currencies is constantly changing, and we use this variance to grow your investment."});
		newListFAQs.push({id:2,q:"How do you make money on the FOREX market?",a:"Essentially the way we make money on the FOREX market is the same as most other trading systems: buy low, sell high.<br /><br />The advantages of foreign exchange are:<ul><li>Foreign exchange rates change much faster than stocks, meaning we can close deals in minutes or hours rather than days or weeks</li><li>Foreign exchange can make profit whether the market is moving up OR down</li><li>FOREX markets don't depend on the economy: even when the economy is bad, FOREX markets can make profit</li></ul> "});
		newListFAQs.push({id:3,q:"What are the risks?",a:"Making a profit on the FOREX markets depends on correctly predicting whether the exchange rates will go up or down. If we can predict correctly, we can grow your money.<br /><br />We use sophisticated AI-based algorithms to predict the direction and these give a very high success rate which means your money will usually grow every month.<br /><br />There is a risk that our algorithm will make incorrect predictions and in this case your investment may lose money in the short term. However, our predictions are over 95% correct, which means that even in the rare event that a deal loses money, the next deal will usually make up for it and you'll be in profit again in no time."});
		newListFAQs.push({id:4,q:"How do I invest money?",a:"Once you have created an account with us, you can pay in money using a credit card.<br /><br />Be aware that transactions are in US$, therefore it is best to use a credit card that doesn't charge you any fees for US$ transactions."});
		newListFAQs.push({id:5,q:"How do I withdraw money?",a:"You can withdraw your money back onto your credit card."});
		newListFAQs.push({id:6,q:"What currency do I pay in?",a:"All our accounts run in US Dollars (USD, US$), because this is the currency that the FOREX markets run on.<br /><br />You should make sure your credit card can accept payments in US$, and be aware of any credit card transaction fees that your card provider might charge for US$ transactions.<br /><br />There are several credit cards that don't have any fees for US$ transactions; we recommend choosing one of these."});
		newListFAQs.push({id:7,q:"Can I use your system to buy foreign money for holidays, sending to friends/family abroad, etc?",a:"No, we use the foreign exchange markets as an investment vehicle. We do not supply or convert currencies."});

		setListFAQs(newListFAQs);
	},[listFAQs]);	
	
	return (<>
		<h1 style={{textAlign:"center",margin:"1rem"}}>FAQ</h1>
		<h2 style={{textAlign:"center",margin:"1rem 0 2rem"}}>Frequently asked questions</h2>
		<div className="faq-container">
			{listFAQs.map((faq,i) => <div key={i} data-q={i} className={"faq"+(selectedFAQ===i ? " selected" : "")}>
				<button className="faq-question" data-q={i} onClick={() => selectFaq(i)}>
					<div className="faq-indicator"></div>
					<div>{faq.q}</div>
				</button>
				<div className="faq-answer">
					<div className="faq-answer-inner" dangerouslySetInnerHTML={{__html:faq.a}} />
				</div>
			</div>)}
		</div>
	</>)
};
