function tooltipShow(e) {
	let target=e.target;
	while(target.dataset.display===undefined && target!==document.body) target=target.parentElement;
	if(target===document.body) return;
	target.dataset.display="block";
	const tooltipWidth=parseInt(window.getComputedStyle(target,"before").width.replace("px",""));
	const screenWidth=document.body.clientWidth;
	const elementRect=target.getBoundingClientRect();
	const preferredPosition=elementRect.x+tooltipWidth>screenWidth ? screenWidth-tooltipWidth-elementRect.x-5 : 0;
	target.style.setProperty("--left",preferredPosition+"px");
}
function tooltipHide(e) {
	let target=e.target;
	while(target.dataset.display===undefined && target!==document.body) target=target.parentElement;
	if(target===document.body) return;
	target.dataset.display="none";
}

export {tooltipShow,tooltipHide};
