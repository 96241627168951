import React,{useContext} from 'react';
import {Link} from "react-router-dom";
import {FxpContext} from './../../Utils/auth.js';
import LogoBW from './../../Assets/logos/logo-bw.png';

export default function Footer(props) {
	const FxpCtx=useContext(FxpContext);
	return (
		<footer className={props.type==="wide" ? "footer-wide" : null}>
			<div className="container">
				<img src={LogoBW} style={{height:"100%",maxHeight:"6rem"}} alt="Forexponential" />
				<div>
					<p><Link to="/" className="no-decoration"><b>Forexponential</b></Link></p>
					<p><Link to="/faq" className="no-decoration">FAQ</Link></p>
					<p><Link to="/articles" className="no-decoration">Articles</Link></p>
					{FxpCtx.userData?.Username ? <>
						<p><Link to="/account" className="no-decoration">Your account</Link></p>
					</> : <>
						<p><Link to="/login" className="no-decoration">Log in</Link></p>
						<p><Link to="/register" className="no-decoration">Create account</Link></p>
					</>}
				</div>
			</div>
		</footer>
	);
}
