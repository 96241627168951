import React from 'react';
import imageHero from './../Assets/images/hero.jpg';
import imageHeroPlaceholder from './../Assets/images/hero-placeholder.jpg';

export default function HomePage() {
	const sectionLearnMore=React.useRef(null);

	return (<>
		<div className="container" style={{backgroundColor:"var(--page-color)",filter:"drop-shadow(0 0 5px rgba(0,0,0,0.4))"}}>
			<div className="hero">
				<div className="hero-box">
					<h1>Beat the banks!</h1>
					<p>You could earn more than 10x the interest rate offered by most banks! In fact you will get a similar rate PER MONTH that most banks offer per YEAR.</p>
					<form className="forexponentialForm" action="register">
						<button type="button" className="cta" onClick={() => {window.scrollTo({top:sectionLearnMore.current.offsetTop-130,behavior:"smooth"})}} style={{display:"inline-flex",paddingRight:"1rem",marginBottom:"5px"}}>
							<div>How does it work?</div>
							<div>&nbsp;</div>
						</button>
						<button type="submit" className="cta" style={{display:"inline-flex"}}>
							<div>Start earning!</div>
							<div>&nbsp;</div>
						</button>
					</form>
				</div>
				<div className="hero-image">
					<img src={imageHeroPlaceholder} alt="Forexponential is a great investment opportunity with fantastic interest rates" />
					<img src={imageHero} alt="Forexponential is a great investment opportunity with fantastic interest rates" loading="lazy" />
				</div>
			</div>
		</div>
		<div style={{backgroundColor:"var(--link-color)",marginTop:"0",color:"var(--light-text-color)"}}>
			<div className="container">
				<div className="row-four">
					<div>
						<i className="fas fa-chart-line fa-4x" />
						<h3>Great returns</h3>
						<p>You can earn more than 10x the interest rate offered by banks</p>
					</div>
					<div>
						<i className="fas fa-flask fa-4x" />
						<h3>Scientific</h3>
						<p>Our algorithms are highly sophisticated but all the hard work is done behind the scenes</p>
					</div>
					<div>
						<i className="fas fa-paper-plane fa-4x" />
						<h3>Easy access</h3>
						<p>You can invest and withdraw any amount of money, at any time</p>
					</div>
				</div>
			</div>
		</div>
		<div ref={sectionLearnMore} className="container" style={{backgroundColor:"var(--page-color)",filter:"drop-shadow(0 0 5px rgba(0,0,0,0.4))"}}>
			<div className="steps-section" style={{"--step-highlight-color":"var(--cta-highlight)","--step-text":"#fff"}}>
				<div className="steps-header">How does it work?</div>
				<div className="steps-container">
					<div className="steps-step">
						<div className="step-number">1</div>
						<div className="step-text">Imagine you change $100 US into UK &pound;s. If the exchange rate is 1.28, you will get &pound;78.12. The next day, if the rate has gone up to 1.3 and you change your &pound;s back to US$, you'll get back $101.56. <strong>Congratulations, you've made $1.56 profit on the FOREX market!</strong></div>
					</div>
					<div className="steps-step">
						<div className="step-number">2</div>
						<div className="step-text">Of course, exchange rates can go down as well as up, and this is where the magic comes in. To <strong>make a stable profit for you</strong>, we need to reliably predict whether the rate will go up or down, and therefore when to buy and when to sell.</div>
					</div>
					<div className="steps-step">
						<div className="step-number">3</div>
						<div className="step-text">We monitor many different currency pairs using <strong>sophisticated algorithms, including AI</strong>. After years of fine-tuning these algorithms, our predictions are <strong>over 90% accurate</strong>. This means we can make a steady profit; and any occasional losses will almost always be recovered very quickly.</div>
					</div>
					<div className="steps-step">
						<div className="step-number">4</div>
						<div className="step-text">When you place your trust in us, we will invest your money into our trading platform and start earning money for you. The effective interest rate is much higher than any other investment: in fact <strong>you'll earn more in one month than most banks offer in a year!</strong></div>
					</div>
					<div className="steps-step">
						<div className="step-number">5</div>
						<div className="step-text">Unlike traditional stock market investments, <strong>it doesn't matter whether the economy is good or bad</strong>. As long as the exchange rates are changing, and we can correctly predict the direction they will go, then we can make a profit for you.</div>
					</div>
					<div className="steps-step">
						<div className="step-number">6</div>
						<div className="step-text">You can withdraw your money at any time: your initial investment, your profits, or both.</div>
					</div>
				</div>
				<form action="register" className="forexponentialForm steps-footer" style={{padding:"0",width:"unset",backgroundColor:"transparent"}}>
					<button className="cta">
						<div style={{padding:"1rem 2rem"}}>Get started now!</div>
						<div style={{padding:"1rem"}}>&nbsp;</div>
					</button>
				</form>
			</div>
		</div>
		<div style={{textAlign:"center",backgroundImage:`url(${require('./../Assets/images/code.jpg')})`,backgroundPosition:"50% 65%",minHeight:"400px",backgroundSize:"cover"}}>
			<div className="container" style={{display:"flex",justifyContent:"center"}}>
{/*				<div style={{background:"var(--page-color)",marginTop:"2rem",padding:"0.5rem 1rem"}}>Next section</div>*/}
			</div>
		</div>
{/*		<div>
			<div className="container">
				<h1>Demo form elements</h1>
				<form className="forexponentialForm">
					<ForexponentialInput Name="Name" Label="Standard input box" />
					<ForexponentialInput Name="Username" Label="E-mail address" Type="email" />
					<div className="form-group">
						<ForexponentialInput Name="Group1" Label="Grouped box" />
						<ForexponentialInput Name="Group2" Label="Grouped box" />
					</div>
					<div className="form-group">
						<ForexponentialInput Name="GroupB" Label="Grouped with a button" />
						<button type="button" className="btn-secondary">Help</button>
					</div>
					<ForexponentialInputPassword Name="Password" Label="Password" Type="create" />
					<ForexponentialInputTextarea Name="Description" Label="Text area" />
					<ForexponentialInputCheckbox ID="checkbox1" Name="Check" Label="Check box" />
					<ForexponentialInputSelect Name="Select" Label="Selector" Options={{1:"Option 1",2:"Option 2",5:"Option 5"}} />
					<div className="form-group">
						<ForexponentialInputSelect Name="Select1" Label="Group selector" Options={{1:"Option 1",2:"Option 2",5:"Option 5"}} />
						<ForexponentialInput Name="Name2" Label="Standard input box" />
						<button type="button">Help</button>
					</div>
					<div className="form-buttons">
						<button type="button">Submit</button>
						<button type="button" className="btn-grey">Ignore</button>
						<button type="button" className="btn-red">Cancel</button>
					</div>
				</form>
			</div>
		</div>*/}
	</>)
};
