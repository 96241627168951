import React from 'react';
import {Helmet} from "react-helmet";

export default function PageHeader(props) {
	const metaTags={keywords:"",robots:"index, follow"};
	switch(props.thisPage) {
		case "":
			metaTags.title="Forexponential | Making the most of your money with bank-beating interest rates";
			metaTags.description="Making the most of your money with bank-beating interest rates";
			break;
		case "faq":
			metaTags.title="Frequently asked questions about Forexponential's high earning system";
			metaTags.description="Frequently asked questions about Forexponential's high earning system";
			break;
		case "articles":
			metaTags.title="The latest Forexponential news";
			metaTags.description="The latest Forexponential news";
			break;
		case "register":
			metaTags.title="Create an account with Forexponential to multiply your money";
			metaTags.description="Create an account with Forexponential to multiply your money";
			break;
		case "login":
			metaTags.title="Log in to your Forexponential account to manage your investment";
			metaTags.description="Log in to your Forexponential account to manage your investment";
			break;
		default:
			metaTags.title="Forexponential";
			metaTags.description="Making the most of your money with bank-beating interest rates";
	}

	return (
		<Helmet>
			<title>{metaTags.title}</title>
			<meta name="description" content={metaTags.description} />
			<meta name="keywords" content={metaTags.keywords} />
			<meta name="robots" content={metaTags.robots} />
			<meta name="theme-color" content="#0E4B72" />
			<link rel="canonical" href={window.location.protocol+"//"+window.location.hostname+(window.location.port==="443" || window.location.port==="80" ? "" : ":"+window.location.port)+(props.thisPage==="" ? "" : "/"+props.thisPage)} />
			<meta name="image" property="og:site_name" content="Forexponential" />
			<meta name="image" property="og:url" content={window.location.protocol+"//"+window.location.hostname+(window.location.port==="443" || window.location.port==="80" ? "" : ":"+window.location.port)+(props.thisPage==="" ? "" : "/"+props.thisPage)} />
			<meta name="image" property="og:title" content="Forexponential" />
			<meta name="image" property="og:image" content={window.location.protocol+"//"+window.location.hostname+(window.location.port==="443" || window.location.port==="80" ? "" : ":"+window.location.port)+"/images/logo.png"} />
			<meta name="image" property="og:description" content={metaTags.description} />
		</Helmet>
	);
}
