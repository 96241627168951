import React,{useContext,Suspense} from 'react';
import {Outlet,Navigate,useLocation} from "react-router-dom";
import {FxpProvider,FxpContext} from './../../Utils/auth.js';
import PageHeader from './layout-headers.js';
import MainMenu from './layout-main-menu.js';
import Footer from './layout-footer.js';
import {DialogBox} from './../../Utils/dialog.js';
import {ForexponentialError} from './../../Utils/error.js';
import Loading from '../../Utils/loading.js';

export const ForexponentialLayout = () => <FxpProvider><Outlet /></FxpProvider>

export const PublicLayout = props => <PageLayout type={props.type} />

export const LoginLayout = props => {
	const FxpCtx=useContext(FxpContext);
	if(FxpCtx.userData?.Username) return <Navigate to="/account" />
	return <PageLayout type={props.type} />
}

export const ProtectedLayout = props => {
	const FxpCtx=useContext(FxpContext);
	if(!FxpCtx.userData?.Username) return <Navigate to="/login" />
	return <PageLayout type={props.type} />
}

export const PageLayout = props => {
	const FxpCtx=React.useContext(FxpContext);
	const location=useLocation();
	const thisPage=location.pathname.substring(1);
	let error;

	// If we're on a page that requires the API, check it is working
	if(thisPage!=="" && thisPage!=="faq") {
		if(FxpCtx.countryList.loaded && !FxpCtx.countryList.TCVersion) {
			error="Sorry, we're currently experiencing difficulties accessing our data. Please try back soon.";
		}
	}

	return (<>
		<PageHeader thisPage={thisPage} />
		<MainMenu type={props.type} thisPage={thisPage} />
		<DialogBox />
			<div className={props.type==="wide" || props.type==="home" ? "root-main wide-main" : "root-main container"}>
			<Suspense fallback={<Loading message="layout" />}>
				{error ? <ForexponentialError error={error} /> : <Outlet />}
				</Suspense>
			</div>
		<Footer type={props.type} />
	</>)
}
