import React from 'react';
import ReactDOM from 'react-dom/client';
import {Route,createBrowserRouter,createRoutesFromElements,RouterProvider} from "react-router-dom";
import HomePage from './Pages/page-home.js';
import FAQPage from './Pages/page-faq.js';
import ArticlesList from './Pages/page-article-list-all.js';
import ArticlesPage from './Pages/page-article-list.js';
import ArticlePage from './Pages/page-article.js';
import LoginPage from './Pages/page-login.js';
import RegisterPage from './Pages/page-register.js';
import ConfirmationCode from './Pages/page-confirm.js';
import LoginReset from './Pages/page-login-reset.js';
import {Error404,ErrorRouter} from './Utils/error.js';
import {ForexponentialLayout,PublicLayout,ProtectedLayout,LoginLayout} from './Pages/layout/layout.js';
import './Assets/index.css';
import './Assets/articles.css';
import './Assets/country-select.css';
import './Assets/faq.css';
import './Assets/main.css';
import './Assets/account.css';

// Lazy load the account page
// function wait(time) {return new Promise(resolve => {setTimeout(resolve,time)})}
// const AccountPage = React.lazy(() => wait(2000).then(() => import('./Pages/page-account.js')))
const AccountPage = React.lazy(() => import('./Pages/page-account.js'))

const API_ROOT=window.location.hostname==="localhost" ? "http://127.0.0.1:85/api-user/"
	: window.location.hostname==="192.168.1.20" ? "http://192.168.1.20:85/api-user/"
	: window.location.hostname==="sandbox.forexponential.com" ? "https://sandbox.forexponential.com/api-user/"
	: "https://www.forexponential.com/api-user/";
//const API_ROOT="http://192.168.1.20:85/api-user/";
const root = ReactDOM.createRoot(document.getElementById('root'));

const router=createBrowserRouter(
	createRoutesFromElements(
		<Route element={<ForexponentialLayout />} errorElement={<ErrorRouter />}>
			<Route element={<PublicLayout type="home" />}>
				<Route path="/" element={<HomePage />} />
			</Route>
			<Route element={<PublicLayout />}>
				<Route path="/faq" element={<FAQPage />} />
				<Route path="/articles" element={<ArticlesPage />} />
				<Route path="/articles-list" element={<ArticlesList />} />
				<Route path="/article/*" element={<ArticlePage />} />
				<Route path="/*" element={<Error404 />} />
			</Route>
			<Route element={<LoginLayout />}>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/register" element={<RegisterPage />} />
			</Route>
			<Route element={<ProtectedLayout />}>
				<Route path="/account" element={<AccountPage />} />
			</Route>
			<Route path="/confirm" element={<ConfirmationCode />} />
			<Route path="/loginreset" element={<LoginReset />} />
		</Route>
	)
);
root.render(
	// <React.StrictMode>
		<RouterProvider router={router} />
	// </React.StrictMode>
);

export {API_ROOT};
