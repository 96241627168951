import React,{useContext} from 'react';
import {FxpContext} from './../Utils/auth.js';
import {API_ROOT} from './../index.js';

export const DialogBox = () => {
	const FxpCtx=useContext(FxpContext);

	// Clicking outside the dialog box closes it
	React.useEffect(() => {
		const checkDialogClose = e => {
			const dialogDimensions=FxpCtx.dialogState.ref.current.getBoundingClientRect();
			if(e.clientX<dialogDimensions.left || e.clientX>dialogDimensions.right || e.clientY<dialogDimensions.top || e.clientY>dialogDimensions.bottom) {
				if(FxpCtx.dialogState.unclosable) {
					FxpCtx.dialogState.ref.current.classList.add("wobble");
					setTimeout(() => FxpCtx.dialogState.ref.current.classList.remove("wobble"),500);
				} else {
					FxpCtx.dialogDispatch({type:"ABORT"});
				}
			}
		};
		FxpCtx.dialogState.ref?.current.addEventListener("click",checkDialogClose);
		return () => FxpCtx.dialogState.ref?.current?.removeEventListener("click",checkDialogClose);
	},[FxpCtx]);

	return (
		<dialog ref={FxpCtx.dialogState.ref}>
			<form method="dialog" className="forexponentialForm" style={{padding:"0",width:"100%"}}>
				{FxpCtx.dialogState.content?.header ? <div className="dialog-header">
					{FxpCtx.dialogState.content.icon ? <div className="dialog-header-icon"><i className={"fas fa-2x "+FxpCtx.dialogState.content.icon} /></div> : null}
					<div className="dialog-header-text">{FxpCtx.dialogState.content.header}</div>
					{!FxpCtx.dialogState.unclosable ? <button type="button" className="dialog-header-close" onClick={() => FxpCtx.dialogDispatch({type:"ABORT"})}><i className="fas fa-times" /></button> : null}
				</div> : null}
				<div className="dialog-body" dangerouslySetInnerHTML={{__html:FxpCtx.dialogState.content?.body}} />
				<div className="dialog-footer">
					{FxpCtx.dialogState.content?.buttons?.map((button,i) => <button key={i} className={button.color} onClick={button.action}>{button.text}</button>)}
					{!FxpCtx.dialogState.unclosable || !FxpCtx.dialogState.content?.buttons ?
						<button type="button" className="btn-red" onClick={() => FxpCtx.dialogDispatch({type:"ABORT"})}>Close</button>
					: null}
				</div>
			</form>
		</dialog>
	)
}

export const dialogReducer = (state,action) => {
	//	ref			useRef to the dialog box
	// 	content			Content of the dialog (icon, header, body, buttons array)
	// 	unclosable		Whether the dialog can be closed before it's finished loading, or by clicking outside the modal
	// 	loading			Currently loading a URL
	// 	cleanup			Cleanup function: usually a controller.abort to cancel a fetch
	// console.log("Dialog got action:",action.type);
	switch(action.type) {
		case "SET_UP":
			return {
				...state,
				ref:action.payload
			}
		case "START_LOAD":
			if(!state.ref.current.hasAttribute("open")) state.ref.current.showModal();
			return {
				...state,
				content:{
					icon:"",
					header:"Loading",
					body:"<div style='text-align:center'><i class='fas fa-spin fa-circle-notch fa-4x' /></div>"
				},
				unclosable:action.unclosable,
				loading:true,
				cleanup:action.cleanup
			}
		case "SHOW_CONTENT":
			if(!state.ref.current.hasAttribute("open")) state.ref.current.showModal();
			return {
				...state,
				content:action.payload,
				loading:false,
				cleanup:() => {}
			}
		case "ABORT":
			if(state.unclosable && state.loading) return state;
			if(state.ref.current.hasAttribute("open")) state.ref.current.close();
			state.cleanup();
			return {
				...state,
				content:{},
				unclosable:false,
				loading:false,
				cleanup:() => {}
			};
		default:
			console.error("UNKNOWN ACTION TYPE in dialogReducer:",action.type)
			return state;
	}
}

export const FetchToModal = (endpoint,header,FxpCtx) => {
	const controller=new AbortController();													// Create a controller to abort the fetch if it is cancelled
	FxpCtx.dialogDispatch({type:"START_LOAD",cleanup:() => controller.abort()});								// Start the modal up and set the abort fucntion
	fetch(API_ROOT+endpoint,{method:"get",signal:controller.signal})									// Make fetch request
	.then(response => response.ok ? response.text() : response.status+" "+response.statusText)						// Return the text or error message from the fetch
	.then(body => FxpCtx.dialogDispatch({type:"SHOW_CONTENT",payload:{header,body}}))							// Show the response in a modal
	.catch(error => {if(error.name!=="AbortError") FxpCtx.dialogDispatch({type:"SHOW_CONTENT",payload:{header,body:error.message}})});	// Show the error in a modal
}
