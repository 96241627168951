import React from 'react';
import {useRouteError} from "react-router-dom";
import {GetApiData} from './../Utils/auth.js';

// This error function is called when a high-level error occurs, for example accessing an article that doesn't exist or a login token is invalid
export function ForexponentialError(props) {
	return (
		<div style={{textAlign:"center",margin:"2rem 0"}}>
			{props.message ? <h2>{props.message}</h2> : ""}
			{props.error ? <p style={{marginTop:"1rem"}}>{props.error}</p> : ""}
		</div>
	)
};

// This error function is called when an error occurs with React Router
export function ErrorRouter() {
	let error = useRouteError();
	React.useEffect(() => {
		return GetApiData("error.aspx",null,{error:error.stack},null,() => {});
	});
	return (<p style={{marginTop:"2rem",textAlign:"center"}}>Sorry, an unexpected error occurred. Please go back to the main site and start again.</p>)
};

// This error function is called when a non-existant route is accesses
export function Error404() {
	return (<>
		<h2 style={{textAlign:"center",marginTop:"1rem"}}>Page not found</h2>
		<p style={{textAlign:"center",marginTop:"1rem"}}>Sorry, this page does not exist. It could be that the URL has changed.</p>
	</>)
};
