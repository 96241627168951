import React,{useContext} from 'react';
import {Link} from "react-router-dom";
import {FxpContext} from './../../Utils/auth.js';
import Logo from './../../Assets/logos/logo-small.png';

export default function MainMenu(props) {
	const FxpCtx=useContext(FxpContext);
	const [menuOpen,setMenuOpen]=React.useState(false);
	const refScrollWatcher=React.useRef(null);
	const refHeader=React.useRef(null);

	// Change menu background transparency on scroll
	React.useEffect(() => {
		const currentScrollWatcher=refScrollWatcher.current;
		const navObserver=new IntersectionObserver(entries => entries.filter(entry => entry.target===currentScrollWatcher).forEach(entry => refHeader.current?.classList.toggle("top",entry.isIntersecting)));
		navObserver.observe(currentScrollWatcher);
		return () => {
			navObserver.disconnect();
		}
	},[props.thisPage]);

	return (<>
		<div data-scroll-watcher ref={refScrollWatcher} />
		<header ref={refHeader} className="top">
			<div className="container">
				<div className="header-inner">
					<div className="header-logo">
						<Link to="/" className="no-decoration" onClick={() => setMenuOpen(false)}>
							<img src={Logo} alt="Forexponential" />
							<p>Forexponential</p>
						</Link>
					</div>
					<button className={"hamburger"+(menuOpen ? " active" : "")} onClick={() => setMenuOpen(!menuOpen)}>
						<div className="hamburger-bar" />
						<div className="hamburger-bar" />
						<div className="hamburger-bar" />
					</button>
				</div>
			</div>
			<nav className={menuOpen ? "active" : ""}>
				<div className="container">
					<ul>
						<li><Link to="/" className={"no-decoration"+(props.thisPage==="" ? " active" : "")} onClick={() => setMenuOpen(false)}>Investing</Link></li>
						<li><Link to="/faq" className={"no-decoration"+(props.thisPage==="faq" ? " active" : "")} onClick={() => setMenuOpen(false)}>Learn more</Link></li>
						<li><Link to="/articles" className={"no-decoration"+(props.thisPage==="articles" ? " active" : "")} onClick={() => setMenuOpen(false)}>Guides</Link></li>
						{FxpCtx.userData?.Username ? <>
							<li className="menu-spacer"></li>
							<li><Link to="/account" className={"no-decoration"+(props.thisPage==="account" ? " active" : "")} onClick={() => setMenuOpen(false)}>Your account</Link></li>
						</> : <>
							<li className="menu-spacer"></li>
							<li><Link to="/login" className={"no-decoration"+(props.thisPage==="login" ? " active" : "")} onClick={() => setMenuOpen(false)}>Log in</Link></li>
							<li className="menu-cta"><Link to="/register" className={"no-decoration"+(props.thisPage==="register" ? " active" : "")} onClick={() => setMenuOpen(false)}>Start earning!</Link></li>
						</>}
					</ul>
				</div>
			</nav>
		</header>
		<div className={"header-blur"+(menuOpen ? " active" : "")} onClick={() => setMenuOpen(false)}></div>
	</>)
}
