import React,{createContext} from 'react';
import {useLocalStorage} from './storage.js';
import {dialogReducer} from './dialog.js';
import {API_ROOT} from './../index.js';

export const FxpContext=createContext();
export function FxpProvider({children}) {
	// Login data and functions
	const [userData,setUserData]=useLocalStorage("user",null);
	const login=async (data) => {setUserData(data)};
	const logout=() => {setUserData(null)};
	const update=(fieldsNew) => setUserData({...userData,...fieldsNew});

	// Dialog data and function
	const dialogRef=React.useRef(null);
	const [dialogState,dialogDispatch]=React.useReducer(dialogReducer,{});
	if(dialogState.ref===undefined) dialogDispatch({type:"SET_UP",payload:dialogRef});

	// Country list
	const [countryList,setCountryList]=React.useState({loaded:false});
	React.useEffect(() => {
		if(countryList.loaded) return;
		const cleanup=GetApiData("country-list.aspx",null,null,null,data => {
			if(data.error)
				setCountryList({loaded:true,...data});
			else
				setCountryList({loaded:true,Countries:[{CountryName:"United Kingdom","Alpha2":"gb",DialingCode:44},{CountryName:"United States of America","Alpha2":"us",DialingCode:1},...data.Countries],TCVersion:data.TCVersion});
		});
		return cleanup;
	},[countryList]);

	const value={userData,login,logout,update,countryList,dialogState,dialogDispatch};
	return <FxpContext.Provider value={value}>{children}</FxpContext.Provider>;
}

// function wait(time) {return new Promise(resolve => {setTimeout(resolve,time)})}

export function GetApiData(endpoint,token,postData,form,handleData) {
	const controller=new AbortController();									// Create a controller to abort the fetch if it is cancelled
	let body=new FormData(form || undefined);								// Make form data if a form is provided
	form?.querySelectorAll("input:disabled").forEach(i => body.append(i.name,i.value));			// Add any hidden form fields
	postData && Object.keys(postData).forEach(key => body.append(key,postData[key]));			// Add all items from the postData object
	let fetchOpts={method:"post",body,signal:controller.signal};						// Make an object for the fetch options
	if(token) fetchOpts.headers={Authorization:"Bearer "+token.AccessToken};				// If we have an auth header, add it
	// wait(3000).then(() => {
		fetch(API_ROOT+endpoint,fetchOpts)									// Make fetch request
		.then(response => response.ok ? response.json() : {error:response.status+" "+response.statusText})	// Return the JSON or error message from the fetch
		.then(data => handleData(data))										// If successful, handle the data
		.catch(error => {if(error.name!=="AbortError") handleData({error:error.message})});			// If error, only handle the data if the request wasn't aborted
		return () => controller.abort();									// Return a function to cancel the fetch
	// });
}
