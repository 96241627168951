import React,{useContext} from 'react';
import {FxpContext,GetApiData} from './../Utils/auth.js';
import {FetchToModal} from './../Utils/dialog.js';
import {ValidateInput,ForexponentialInput,ForexponentialInputPassword,ForexponentialInputAddress,ForexponentialInputPhone,ForexponentialInputCheckbox,ForexponentialInputError} from './../Utils/form-fields.js';

export default function RegisterPage() {
	const [signingUp,setSigningUp]=React.useState({});
	const [phoneCountry,setPhoneCountry]=React.useState({flag:"gb",code:"+44"});
	const [addressCountry,setAddressCountry]=React.useState({flag:"gb",name:"United Kingdom"});
	const FxpCtx=useContext(FxpContext);
	const phoneRef=React.useRef(null);
	const setPhoneCountryDo=(newCountry) => {
		setPhoneCountry(newCountry);
		setAddressCountry({flag:newCountry.flag,name:FxpCtx.countryList?.Countries?.find(country => country.Alpha2===newCountry.flag).CountryName});
	}
	const handleSignup = e => {
		e.preventDefault();
		setSigningUp({active:true});
		let inputValid=true;
		e.target.querySelectorAll("input").forEach(input => {inputValid=(ValidateInput(input)===false ? false : true) && inputValid});
		if(!inputValid) return setSigningUp({});
		GetApiData("register.aspx",null,null,e.target,data => {
			if(data.error) return setSigningUp(data);
			setSigningUp({});
			FxpCtx.login(data);
		});
	}

	return (<>
		<h2 style={{textAlign:"center",margin:"1rem"}}>Please enter your details</h2>
		<form className="forexponentialForm narrow" onSubmit={handleSignup}>
			<div className="form-header" style={{textAlign:"center"}}>Contact details</div>
			<ForexponentialInput ID="Email" Name="Email" Label="E-mail address" Type="email" />
			<ForexponentialInput Name="Name" Label="Your name" />
			<ForexponentialInputPhone Ref={phoneRef} ID="Phone" Name="Phone" Label="Mobile" Country={{flag:phoneCountry.flag,code:phoneCountry.code,setCountry:setPhoneCountryDo}} />
			<div className="form-header" style={{textAlign:"center"}}>Your address</div>
			<ForexponentialInputAddress ID="Address" Name="Address" Label="Address" Address={{AddressAlpha2:addressCountry.flag,AddressCountry:addressCountry.name,setCountry:setAddressCountry}} />
			<div className="form-header" style={{textAlign:"center"}}>Create a password</div>
			<ForexponentialInputPassword Name="Password" Label="Password" Type="create" />
			<input type="hidden" name="TCVersion" defaultValue={FxpCtx.countryList.TCVersion} />
			<p style={{margin:"1rem"}}>Please read our <button type="button" className="btn-link" onClick={() => FetchToModal("terms.aspx","Terms and conditions",FxpCtx)}>terms and conditions</button> and <button type="button" className="btn-link" onClick={() => FetchToModal("privacy.aspx","Privacy policy",FxpCtx)}>privacy policy</button></p>
			<ForexponentialInputCheckbox ID="TCAgreed" Name="TCAgreed" Label="I have read and agree to the terms and conditions and privacy policy linked above" />
			<div className="form-buttons">
				<button type="submit" style={{minWidth:"12rem"}}>{signingUp.active ? <i className="fas fa-circle-notch fa-spin" /> : "Create account"}</button>
			</div>
			<ForexponentialInputError Message={signingUp.error ? [signingUp.error] : []} MarginTop={true} />
		</form>
	</>)
};
