import React,{useRef} from 'react';
import {Link} from "react-router-dom";
import {GetApiData} from '../Utils/auth.js';
import {ForexponentialError} from '../Utils/error.js';
import defaultCoverImage from "../Assets/images/background1.jpg";
import Loading from '../Utils/loading.js';

export default function ArticlesPage() {
	const articlesPerPage=6,articlesPerMore=3;
	const [articleList,setArticleList]=React.useState({loaded:false});
	const articleRefs=useRef([]);

	const handleClickMore=() => {
		if(articleList.Articles.length>articleList.shown) setArticleList({...articleList,shown:articleList.shown+articlesPerMore,scrollTo:articleList.shown});
	}

	React.useEffect(() => {
		if(articleList.scrollTo) window.scrollTo({top:articleRefs.current[articleList.scrollTo].offsetTop-90,behavior:"smooth"});
		if(articleList.loaded!==false) return;
		const cleanup=GetApiData("article-list.aspx",null,null,null,data => setArticleList({loaded:true,shown:articlesPerPage,...data}));
		return () => cleanup;
	},[articleList])

	if(!articleList.loaded) return <Loading message="article list" />
	if(articleList.error) return <ForexponentialError message="Apologies, we seem to be having difficulty accessing the articles." error={articleList.error} />
	if(!articleList.Articles || articleList.Articles.length===0) return <ForexponentialError message="Apologies, we couldn't find any articles. Please check back later." />

	return (<>
		<h1 style={{textAlign:"center",margin:"1rem"}}>Articles</h1>
		<div className="articles-container">
			{articleList.Articles.filter((article,i) => i<articleList.shown).map((article,i) => 
				<article key={article.ID} ref={el => articleRefs.current[i]=el} style={{backgroundImage:`url(${article.Image || defaultCoverImage})`}}>
					<div className="content">
						<h3 className="heading">{article.Title}</h3>
						<div>
							<div>
								<p style={{margin:"0.3rem 0 1rem"}}>{article.Summary}</p>
							</div>
						</div>
						<div>
							<Link to={"/article/"+article.ID+"/"+article.Summary.replaceAll(" ","-")} className="no-decoration cta" style={{margin:"0"}}>
								<div>Read more</div>
								<div>&nbsp;</div>
							</Link>
						</div>
					</div>
				</article>
			)}
		</div>
		{articleList.Articles.length>articleList.shown ?
			<form className="forexponentialForm" style={{margin:"1rem auto"}}>
				<button type="button" className="cta" onClick={handleClickMore}>
					<div>More articles</div>
					<div>&nbsp;</div>
				</button>
			</form>
		: null}
	</>)
};
