import React,{useContext} from 'react';
import {FxpContext,GetApiData} from './../Utils/auth.js';
import {ValidateInput,ForexponentialInputPassword,ForexponentialInputError} from './../Utils/form-fields.js';
import {ForexponentialError} from './../Utils/error.js';
import {useSearchParams,Link} from "react-router-dom";
import Loading from '../Utils/loading.js';

export default function LoginReset() {
	const FxpCtx=useContext(FxpContext);
	const [searchParams]=useSearchParams();
	const [confirmation,setConfirmation]=React.useState({loaded:false});
	const [resetting,setResetting]=React.useState({});
	const Code=searchParams.get("Code");
	React.useEffect(() => {
		if(confirmation.loaded) return;
		return GetApiData("login-reset-check.aspx",null,{Code},null,data => setConfirmation({loaded:true,...data}));
	},[Code,confirmation])

	const handleReset = e => {
		e.preventDefault();
		setResetting({active:true});
		let inputValid=true;
		e.target.querySelectorAll("input").forEach(input => {inputValid=(ValidateInput(input)===false ? false : true) && inputValid});
		if(!inputValid) return setResetting({});
		GetApiData("login-reset-do.aspx",null,null,e.target,data => {
			if(data.error) return setResetting(data);
			setResetting({done:true});
			FxpCtx.login(data);
		});
	}

	if(!confirmation.loaded) return <Loading message="login reset" />
	if(confirmation.outcome!=="ok") return <ForexponentialError message="Apologies, we seem to be having difficulty accessing your account data." error={confirmation.error} />
	if(resetting.done) return (
		<div style={{textAlign:"center",margin:"2rem 0"}}>
			<h2>Your password has been reset</h2>
			<p style={{marginTop:"1rem"}}>Please <Link to="/login">log in</Link> to continue to your account.</p>
		</div>
	)

	return (
		<div>
			<h2 style={{textAlign:"center",margin:"1rem"}}>Please enter a new password</h2>
			<form className="forexponentialForm narrow" onSubmit={handleReset}>
				<input type="hidden" name="Code" defaultValue={Code} />
				<ForexponentialInputPassword Name="Password" Label="Password" Type="create" />
				<div className="form-buttons">
					<button type="submit" style={{minWidth:"12rem"}}>{resetting.active ? <i className="fas fa-circle-notch fa-spin" /> : "Update"}</button>
				</div>
				<ForexponentialInputError Message={resetting.error ? [resetting.error] : []} MarginTop={true} />
			</form>
		</div>
	)
};
