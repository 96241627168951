import React,{useContext} from 'react';
import {FxpContext} from './../Utils/auth.js';
import {tooltipShow,tooltipHide} from './../Utils/tooltip.js';

function ValidateInput(target) {
	if(target.disabled) return true;
	if(target.dataset.type==="password") {
		if(target.value==="") return target.dataset.validated=false;
		return target.dataset.validated==="true" ? true : false;
	}
	if(target.dataset.type==="email") return target.dataset.validated=/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(target.value);
	if(target.dataset.type==="numeric") target.value=target.value.replace(/[^0-9]/g,'');
	if(target.dataset.type==="numeric" && target.maxLength>0) target.value=target.value.substring(0,target.maxLength);
	if(target.value==='') {
		if(target.dataset.allowEmpty==="true")
			return target.dataset.validated="";
		else
			return target.dataset.validated=false;
	} else return target.dataset.validated=true;
}
function PasswordCheck(password) {
	if(password==='') return [];
	const testList=[];
	if(password.length<8) testList.push("Must be at least 8 characters long");						// At least 8 characters long
	if(!/[a-z]/.test(password)) testList.push("Must contain a lower-case letter");						// Contains an upper-case letter
	if(!/[A-Z]/.test(password)) testList.push("Must contain an upper-case letter");						// Contains a lower-case letter
	if(!/\d/.test(password)) testList.push("Must contain a number");							// Contains a number
	if(!/[!"£$%^&*(){}[\]`\\|,.<>/?;:'@#~\-_+=]/.test(password)) testList.push("Must contain a special character");		// Contains a special character
	if(/(.)\1{2,}/.test(password)) testList.push("Must not have the same character repeated 3 or more times");		// The same character repeated 3 or more times
	return testList;
}
function ForexponentialInput(props) {
	const types={email:"email",numeric:"number",date:"date"};
	return (
		<div className="form-floating" style={{width:props.Width ? props.Width==="flex" ? " form-floating-flex-width" : props.Width : null}}>
			<input
				ref={props.Ref}
				type={types[props.Type] || "text"}
				placeholder={props.Placeholder || " "}
				id={props.ID}
				name={props.Name}
				maxLength={props.MaxLength}
				min={props.Min}
				max={props.Max}
				data-type={props.Type}
				data-allow-empty={props.AllowEmpty}
				style={{textAlign:props.Align}}
				onChange={props.ChangeFunction || function(e) {ValidateInput(e.target)}}
				defaultValue={props.Value}
				autoComplete={props.autoComplete} />
			{props.Label ? <label>{props.Label}</label> : ""}
		</div>
	)
}
function ForexponentialInputTextarea(props) {
	return (
		<div className="form-floating" style={{height:"100px"}}>
			<textarea
				placeholder=" "
				id={props.ID}
				name={props.Name}
				data-type={props.Type}
				onChange={e => ValidateInput(e.target)}
				defaultValue={props.Value} />
			<label>{props.Label}</label>
		</div>
	)
}
function ForexponentialInputCheckbox(props) {
	return (
		<div className="form-floating">
			<input
				type="checkbox"
				id={props.ID}
				name={props.Name}
				ref={props.Ref}
				defaultChecked={props.Checked}
				onClick={props.CheckFunction} />
			<label htmlFor={props.ID}>{props.Label}</label>
		</div>
	)
}
function ForexponentialInputRadio(props) {
	return (
		<div className="form-floating">
			<input
				type="radio"
				id={props.ID}
				name={props.Name}
				value={props.Value}
				checked={props.Setting===props.Value}
				onChange={() => props.Setter(props.Value)}
			 />
			<label htmlFor={props.ID}>{props.Label}</label>
		</div>
	)
}
function ForexponentialInputSelect(props) {
	return (
		<div className="form-floating" style={{width:props.Width ? props.Width==="flex" ? " form-floating-flex-width" : props.Width : ""}}>
			<select id={props.ID} name={props.Name} onChange={e => {if(props.ChangeFunction) props.ChangeFunction(e.target.value)}}>
				{Object.keys(props.Options).map((key,i) => <option key={i} value={key}>{props.Options[key]}</option>)}
			</select>
			<label>{props.Label}</label>
		</div>
	)
}
function ForexponentialInputPassword(props) {
	const [password,setPassword]=React.useState({});
	return (
		<>
			<div className="form-group">
				<div className="form-floating">
					<input
						type={password.passwordShown ? "text" : "password"}
						placeholder=" "
						ref={props.Ref}
						id={props.ID}
						name={props.Name}
						data-type="password"
						data-validated={!password.password ? "" : password.warnings?.length===0 ? "true" : "false"}
						onChange={e => {setPassword({...password,password:e.target.value,warnings:PasswordCheck(e.target.value)})}}
						autoComplete="off" />
					<label>Password</label>
				</div>
				<button type="button" className="btn-secondary" style={{width:"3.5rem"}} onClick={() => setPassword({...password,passwordShown:!password.passwordShown})}>
					<i className={"fas "+(password.passwordShown ? "fa-eye" : "fa-eye-slash")} />
				</button>
			</div>
			{props.Type==="create" ? <ForexponentialInputError Message={password.warnings} /> : ""}
		</>
	)
}
function ForexponentialInputPhone(props) {
	const FxpCtx=useContext(FxpContext);

	return (
		<div className="form-group" style={{position:"relative"}}>
			<input type="hidden" disabled id={props.ID+"Alpha2"} name={props.Name+"Alpha2"} value={props.Country?.flag || ""} onChange={() => {}} />
			<button type="button" className="btn-secondary" style={{width:"3.5rem",padding:"0"}}>
				<div className="country-select-button">
					<div className={"country-select-flag "+props.Country?.flag}></div>
					<i className="fas fa-caret-down" style={{paddingLeft:"4px"}} />
				</div>
			</button>
			<ul className="country-select-list">
				{!FxpCtx.countryList.loaded || FxpCtx.countryList.error ? 
					<div style={{padding:"2rem"}}>{FxpCtx.countryList.error || "Not loaded"}</div>
				: FxpCtx.countryList.Countries?.map((country,i) => (
					<li key={i} value={country.DialingCode} onMouseDown={e => e.preventDefault()} onClick={e => {
						props.Country.setCountry({code:"+"+country.DialingCode,flag:country.Alpha2});
						props.phoneNumber.current.focus();
					}}>
						<div className={"country-select-flag "+country.Alpha2}></div>
						<span>{country.CountryName}</span>
						<span>+{country.DialingCode}</span>
					</li>
				))}
			</ul>
			<div className="form-floating" style={{flex:"0 0 4rem"}}>
				<input type="text" disabled id={props.ID+"Code"} name={props.Name+"Code"} value={props.Country?.code || ""} onChange={() => {}} />
			</div>
			<div className="form-floating" style={{flex:"1 1 10rem"}}>
				<input type="tel" placeholder=" " ref={props.Ref} id={props.ID+"Number"} name={props.Name+"Number"} data-type="numeric" onChange={e => ValidateInput(e.target)} defaultValue={props.Number} />
				<label>Mobile</label>
			</div>
		</div>
	)
}
function ForexponentialInputAddress(props) {
	const FxpCtx=useContext(FxpContext);
	const addressAlpha2=React.useRef(null);
	const addressCountrySel=React.useRef(null);
	return (
		<div style={{display:"flex"}}>
			<label style={{padding:"0.7rem 1rem 0 0"}} dangerouslySetInnerHTML={{__html:props.Label}} />
			<div style={{flex:"1"}}>
				<input type="hidden" disabled ref={addressAlpha2} id={props.ID+"Alpha2"} name={props.Name+"Alpha2"} defaultValue={props.Address?.AddressAlpha2} />
				<div style={{position:"relative",marginTop:"0.2rem"}}>
					<button type="button" className="btn-grey" ref={addressCountrySel} id={props.ID+"CountrySel"} style={{width:"100%",padding:"0",fontWeight:"400",border:"1px solid var(--form-border-color)",borderLeftWidth:"3px"}}>
						<div className="country-select-button">
							<div className={"country-select-flag "+props.Address?.AddressAlpha2}></div>
							<div style={{display:"inline-block",padding:"0 1rem"}}>{props.Address?.AddressCountry || "Country"}</div>
							<i className="fas fa-caret-down" style={{paddingLeft:"4px"}} />
						</div>
					</button>
					<ul className="country-select-list">
					{!FxpCtx.countryList.loaded || FxpCtx.countryList.error ? 
						<div style={{padding:"2rem"}}>{FxpCtx.countryList.error || "Not loaded"}</div>
					: FxpCtx.countryList.Countries?.map((country,i) => (
						<li key={i} value={country.DialingCode} onMouseDown={e => e.preventDefault()} onClick={e => {
							props.Address.setCountry({name:country.CountryName,flag:country.Alpha2});
							addressAlpha2.current.value=country.Alpha2;
							addressCountrySel.current.blur();
						}}>
							<div className={"country-select-flag "+country.Alpha2}></div>
							<span>{country.CountryName}</span>
						</li>
					))}
					</ul>
				</div>
				<ForexponentialInput Ref={props.Ref} Name="Address1" Label="Address line 1" AllowEmpty="true" Value={props.Address?.Address1} />
				<ForexponentialInput Name="Address2" Label="Address line 2" AllowEmpty="true" Value={props.Address?.Address2} />
				<ForexponentialInput Name="Address3" Label="Address line 3" AllowEmpty="true" Value={props.Address?.Address3} />
				<ForexponentialInput Name="AddressTown" Label="Town / city" Value={props.Address?.AddressTown} />
				<ForexponentialInput Name="AddressCounty" Label="County / state" Value={props.Address?.AddressCounty} />
				<ForexponentialInput Name="AddressPostcode" Label="Postcode / ZIP" Value={props.Address?.AddressPostcode} />
			</div>
		</div>
	)
}
function ForexponentialHelpButton(props) {
	return (
		<button className="btn-secondary btn-help" data-text={props.HelpText} data-display="none" style={{padding:"0",width:props.Width}} onMouseOver={tooltipShow} onMouseOut={tooltipHide}>?</button>
	)
}

function ForexponentialInputCreditCard() {
	const expiryYear=React.useRef(null);
	const refCVV=React.useRef(null);
	function validateCC(e) {
		e.target.value=e.target.value.substring(0,20).replace(/[^0-9]/g,'');
		const arr=(e.target.value+'').split('').reverse().map(x => parseInt(x));
		const luhnDigit=arr.splice(0,1)[0];
		const sum=arr.reduce((acc,val,i) => {
			const d=i%2===0 ? val*2 : val;
			return acc+(d>9 ? d-9 : d);
		},0);
		if((sum+luhnDigit)%10!==0) return e.target.dataset.validated="false";
		e.target.dataset.validated="true";
	}
	function validateMonth(e) {
		e.target.value=e.target.value.substring(0,2).replace(/[^0-9]/g,'');
		if(e.target.value<1 || e.target.value>12) return e.target.dataset.validated="false";
		e.target.dataset.validated="true";
		if(e.target.value.length>1) {expiryYear.current.focus(); expiryYear.current.select();}
	}
	function validateYear(e) {
		e.target.value=e.target.value.substring(0,2).replace(/[^0-9]/g,'');
		if(e.target.value<new Date().getFullYear()-2000 || e.target.value>50) return e.target.dataset.validated="false";
		e.target.dataset.validated="true";
		if(e.target.value.length>1) {refCVV.current.focus(); refCVV.current.select();}
	}
	return (<>
		<ForexponentialInput ID="CCNumber" Name="CCNumber" Label="Card number" Type="numeric" MaxLength="20" ChangeFunction={validateCC} Value="4929421234600821" />
		<div className="form-group" style={{flexWrap:"wrap",justifyContent:"center"}}>
			<div className="form-linked-boxes">
				<ForexponentialInput Name="ExpiryMonth" Width="3rem" Type="numeric" Placeholder="mm" Align="center" MaxLength="2" ChangeFunction={validateMonth} />
				<div className="form-linked-text">/</div>
				<ForexponentialInput ID="ExpiryYear" Ref={expiryYear} Name="ExpiryYear" Width="3rem" Type="numeric" Placeholder="yy" Align="center" MaxLength="2" ChangeFunction={validateYear} />
				<label>Expiry date</label>
			</div>
			<ForexponentialInput Ref={refCVV} ID="CVV" Name="CVV" Label="CVV" Width="5rem" Type="numeric" MaxLength="4" />
			<ForexponentialHelpButton Width="10%" HelpText="CVV is the last set of digits on the signature strip on the back of the card" />
		</div>
	</>)
}

function ForexponentialInputError(props) {
	return (
		<div className={"form-error"+(props.Message?.length ? " active" : "")} style={{marginTop:props.MarginTop ? "3px" : ""}}>
			<div><i className="fas fa-exclamation-triangle fa-2x" /></div>
			<div><div>{props.Message?.map((message,i) => <p key={i}>{message}</p>)}</div></div>
			<div><i className="fas fa-exclamation-triangle fa-2x" /></div>
		</div>
	)
}

export {ValidateInput,PasswordCheck,ForexponentialInput,ForexponentialInputTextarea,ForexponentialInputCheckbox,ForexponentialInputRadio,
	ForexponentialInputSelect,ForexponentialInputPassword,ForexponentialInputPhone,
	ForexponentialInputAddress,ForexponentialInputCreditCard,ForexponentialInputError};
